@tailwind base;
@tailwind components;
@tailwind utilities;

.fill-under-top-bar {
  height: calc(100dvh - 4rem);
}

.min-fill-under-top-bar {
  min-height: calc(100dvh - 4rem);
}

.three-d-container {
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
}

.three-d-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsl(0deg 0% 0% / 0.25);
  transform: translateY(4px);
}

.three-d-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 1px;
}

.three-d-front {
  display: block;
  position: relative;
  padding: 4px 4px;
  transform: translateY(-6px);
  border-width: 1px;
}

.three-d-container.pushable:active .three-d-front {
  transform: translateY(-2px);
}

.three-d-container.pushable:active .three-d-shadow {
  transform: translateY(1px);
}

.three-d-container:focus:not(:focus-visible) {
  outline: none;
}
